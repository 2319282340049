<template>
   <section>
        <div id="doubts">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <div>
                            <div class="section-title" align="center">{{ $t('doubtsComponent.doubts_question') }}</div>
                        </div>
                        <div class="row justify-content-center">
                            <button type="button" class="btn-blue" @click="showCallRequestModal">{{ $t('doubtsComponent.call_request') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CallRequestModal 
            ref="callRequestRef" 
            :phoneContact= "contactInfo.telephone"
            @show-report-sinister="showReportSinisterModal" />

        <ReportSinisterModal 
            ref="reportSinisterRef"
            :currentLanding='currentLanding' />
    </section>
</template>

<script>

import CallRequestModal from '@/components/modals/CallRequestModal.vue'
import ReportSinisterModal from '@/components/modals/ReportSinisterModal.vue'
import { getters } from '@/state/store.js'
import contactInfo from '@/config/constants/contactInfo.js'

export default {
    name: "DoubtsComponent",
    components: { 
        CallRequestModal,
        ReportSinisterModal
    },
     data: function () {
        return {
            contactInfo
        }
    },
    methods: {
        showCallRequestModal(){
            this.$refs.callRequestRef.showModal()
        },
        showReportSinisterModal(){
            this.$refs.reportSinisterRef.showModal()
        }
    },
    computed: {
        currentLanding: getters.currentLanding
    }
}
</script>

<style lang="scss" scoped>

#doubts {
    padding: 70px 40px;
}

#doubts .section-title {
    margin-bottom: 25px;
}

#doubts .row.justify-content-center button  {
    width: auto;
}
</style>
