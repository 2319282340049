<template>
    <li class="item-menu relative">
        <a :href= url>
          <span :class="`menu-insurances__img ${this.$i18n.locale}`">
              <img :src="getImgPath()">
          </span>
          <slot></slot>
        </a>
    </li>
</template>
<script>

export default {
    name: 'InsuranceButton',
    props: {
      imageName: String,
      url: String
    },
    methods: {
      getImgPath() {
        return require('../../assets/header/'+ this.imageName)
      }
    }
}
</script>

<style lang="scss" scoped>

</style>
