<template>
    <div :class="`fix-height-mobile-menu-insurances ${this.$i18n.locale}`">
        <div class="wrapper-menu wrapper-menu--insurances">
            <div class="container-menu">
                <nav class='container-menu__nav'>
                    <ul class="menu-contact">
                        <li>
                            <a target="_blank"
                                @click="goClientArea"
                                href="tuseguromovilapp://?peritacion=1&policy_identifier=20210408oE4uPZeW2ftd7nuMV9ZcGmyPU1XiVmuR">
                                <i class="mr-2"><mdi-icon icon="mdiAccount" /></i>{{ $t('headerComponent.client_area') }}
                            </a>
                        </li>
                        <li>
                            <a href="/mapa">
                                <i class="mr-2"><mdi-icon icon="mdiStore" /></i>
                                {{ $t('headerComponent.stores_map') }}
                            </a>
                        </li>
                        <li class="btn-show-apps-dropdwon">
                            <a href="#!" role="button" @click="toggleCollapse">
                                <i class="mr-2"><mdi-icon icon="mdiArrowDownCircle" /></i>
                                {{ $t('headerComponent.download_app') }}
                            </a>
                            <transition name="list">
                                <div v-show="isCollapseAccordionApp">
                                    <div class="app-stores-btns">
                                        <a rel="nofollow" :href= contactInfo.ios target="_blank" class="app-store-white"></a>
                                        <a rel="nofollow" :href= contactInfo.android target="_blank" class="google-play-white"></a>
                                    </div>
                                </div>
                            </transition>
                        </li>
                        
                    </ul>
                    <ul class="menu-insurances">
                        <InsuranceButton :imageName="getMenuIcon('seguromovil')" :url= navLink.seguromovil ><div :class="{ orange: isCurrentLanding('seguromovil') }" v-html="$t('headerInsurances.mobile_insurance')"></div></InsuranceButton>
                        <!--<InsuranceButton imageName="seguropatinete.png" :url= navLink.seguropatinete><span :class="{ orange: isCurrentLanding('seguropatinete') }" v-html="$t('headerInsurances.scooter_insurance')"></span></InsuranceButton>-->
                        <InsuranceButton :imageName="getMenuIcon('seguromultidispositivo')" :url= navLink.seguromultidispositivo><span :class="{ orange: isCurrentLanding('seguromultidispositivo') }" v-html="$t('headerInsurances.multidevice_insurance')"></span></InsuranceButton>
                        <InsuranceButton :imageName="getMenuIcon('seguroportatil')" :url= navLink.seguroportatil><span :class="{ orange: isCurrentLanding('seguroportatil') }" v-html="$t('headerInsurances.laptop_insurance')"></span></InsuranceButton>
                        <!--<InsuranceButton imageName="extensiondegarantia.png" :url= navLink.extensiondegarantia><span :class="{ orange: isCurrentLanding('extensiondegarantia') }" v-html="$t('headerInsurances.warranty_insurance')"></span></InsuranceButton>-->
                    </ul>
                    
                </nav>
            </div>
                
        </div>
        <div class="menu-insurances_mobile">
            <ul>
                <InsuranceButton :imageName="getMenuIcon('seguromovil')" :url= navLink.seguromovil ><div :class="{ orange: isCurrentLanding('seguromovil') }"></div></InsuranceButton>
                <!--<InsuranceButton imageName="seguropatinete.png" :url= navLink.seguropatinete><span :class="{ orange: isCurrentLanding('seguropatinete') }"></span></InsuranceButton>-->
                <InsuranceButton :imageName="getMenuIcon('seguromultidispositivo')" :url= navLink.seguromultidispositivo><span :class="{ orange: isCurrentLanding('seguromultidispositivo') }"></span></InsuranceButton>
                <InsuranceButton :imageName="getMenuIcon('seguroportatil')" :url= navLink.seguroportatil><span :class="{ orange: isCurrentLanding('seguroportatil') }"></span></InsuranceButton>
                <!--<InsuranceButton imageName="extensiondegarantia.png" :url= navLink.extensiondegarantia><span :class="{ orange: isCurrentLanding('extensiondegarantia') }"></span></InsuranceButton>-->
            </ul>
        </div>
    </div>
    
</template>

<script>
import InsuranceButton from './InsuranceButton.vue'
import navLink from '@/config/constants/navLink.js'
import contactInfo from '@/config/constants/contactInfo.js'

export default {
    name: 'HeaderInsurances',
    props: ['currentLanding'],
    components: { 
        InsuranceButton
    },
    data: () => ({
        isCollapseAccordionApp: false,
        navLink,
        contactInfo
    }),
    methods: {
        toggleCollapse(event) {
            event.preventDefault();
            this.isCollapseAccordionApp = !this.isCollapseAccordionApp
        },
        getMenuIcon(insurance) {
            return `${this.$i18n.locale}_${insurance}.svg`
        },
        goClientArea(event) {
            event.preventDefault();

            const href = event.target.href;
            window.location = href;

            setTimeout(() => {
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
                    if (/Android/i.test(navigator.userAgent)){
                        window.location = contactInfo.android
                    } else if(/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                        window.location = contactInfo.ios
                    }
                    // Si no es Android o iOS
                    // Por defecto se manda a Android Store. Se deja así, por si se decide tomar otra via
                    window.location = contactInfo.android
                } else {
                    // Si no es Dispositivo móvil
                    // Por defecto se manda a Android Store. Se deja así, por si se decide tomar otra via
                    window.location = contactInfo.android
                }
            }, 1000);
        },
        isCurrentLanding(insurance) {
            return insurance === this.currentLanding
        },
        isMxBroker() {
           return this.$i18n.locale == 'mx'
        },
    }
}
</script>

<style lang="scss" scoped>
@keyframes list-enter-leave {
    0% {
        height: 0;
        opacity: 0;
    }
    70% {
        opacity: 0;
    }
    100% {
        height: 60px;
        opacity: 1;
    }
}

.list-enter {
    height: 0;
}
.list-enter-to {
    animation: list-enter-leave .3s linear forwards;
}
.list-leave-to {
    animation: list-enter-leave .3s linear reverse;
}
.list-enter,
.list-leave-to {
    visibility: hidden;
    opacity: 0;
}
</style>
