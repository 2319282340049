<template>
    <footer>    
        <div class="container">
            <div id="top-buttons">
                <div class="contentBtn">
                    <div class="col-12 col-lg-auto btn-white-image">
                        <div><a class="orange" @click="showReportSinisterModal"><img src="@/assets/icons/siniestro.png" height="25" style="vertical-align:top"> {{ $t('footerComponent.declare_sinister') }} </a></div>
                    </div>
                    <div class="col-12 col-lg-auto btn-white-image">
                        <div><a class="orange" target="_blank" :href="clientArea"><img src="@/assets/icons/area-cliente.png" height="25" style="vertical-align:top"> {{ $t('footerComponent.client_area') }} </a></div>
                    </div>
                    <div class="col-12 col-lg-auto btn-white-image"> 
                        <div><a class="orange" target="_blank" :href="distributorArea"><img src="@/assets/icons/area-distribuidor.png" height="25" style="vertical-align:top"> {{ $t('footerComponent.distributor_area') }} </a></div>
                    </div>
                </div>
            </div>
            <FooterLinks :currentLanding="this.currentLanding" />
            <FooterLegality></FooterLegality>
            <div class="container">
                <div class="col-12 claim-legal">
                    <strong>{{ $t('footerComponent.copyright') }}</strong>
                    <br>
                    {{ $t('footerComponent.commercial_register') }}
                    <br>
                    {{ $t('footerComponent.commercial_register_2') }}
                </div>
            </div>
        </div>
        <ReportSinisterModal 
            ref="reportSinisterRef"
            :currentLanding='currentLanding' />
    </footer>
</template>

<script>
import ReportSinisterModal from '../../components/modals/ReportSinisterModal.vue'
import FooterLinks from './FooterLinks.vue'
import FooterLegality from './FooterLegality'
import { getters } from '@/state/store.js'

export default {
    name: "FooterComponent",
    components: { 
        FooterLinks,
        FooterLegality,    
        ReportSinisterModal
    },
    methods: {
        showReportSinisterModal(){
            this.$refs.reportSinisterRef.showModal()
        },
    },
    computed: {
        currentLanding: getters.currentLanding,
        clientArea(){
            return process.env.VUE_APP_CLIENT_AREA_URL 
        },
        distributorArea(){
            return process.env.VUE_APP_DISTRIBUTOR_AREA_URL 
        }
    }
}
</script>
<style lang="scss" scoped>

footer {
    background: $gray-color-light;
    padding: 40px 0;
}

#top-buttons .contentBtn{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

footer .container .claim-legal{
    text-align: center;
    font-size: 0.6rem;
}
</style>
