<template>
    <div id="footer-links">
        <div :class="`footer-content ${this.$i18n.locale}`">
            <div class="footer-description" v-for="column in columns" v-bind:key="column.column">
                <div id= "column" class="col-12  mt-2">
                    <div><p class="header">{{ column.headerTitle }}</p></div>
                    <div v-for="link in column.links" v-bind:key="link.title">
                        <div>
                            <a v-if="!link.is_function" :target= link.target :href="getHRef(link.href)" :alt=link.title>{{ link.title }}</a>
                            <a v-else href="javascript:void(0);" @click="faqsButtonClicked" :alt=link.title>{{ link.title }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import landing from '@/config/constants/landing.js'
import navLink from '@/config/constants/navLink.js'
import { bus } from '@/main.js';
import events from '@/config/constants/events.js'

export default {
    name: "FooterComponent",
    props: ['currentLanding'],
    data(){
        return{
            columns: this.getColumns(),
            navLink
        }
    },
    methods: {
        getColumns(){
            var columns = new Array();
            var insurancesFiltered = this.$t('footerComponent.linksInsurance').filter((x) => {return x.landing === this.currentLanding})[0]
            if (typeof insurancesFiltered !== "undefined" && insurancesFiltered.length > 0) {
                columns.push(insurancesFiltered.columns[0], insurancesFiltered.columns[1])
            }
            var othersInsurancesHeaderTitle = this.$t('footerComponent.links_others_insurances').headerTitle
            var othersInsurancesLinks = this.$t('footerComponent.links_others_insurances').links.filter((x) => {return x.landing !== this.currentLanding})
            var othersInsurances = {headerTitle: othersInsurancesHeaderTitle, links: othersInsurancesLinks}
            columns.push(othersInsurances)
            columns.push(this.$t('footerComponent.links_us'))
            return columns
        },
        getHRef(hRef){
            switch (hRef) {
                case landing.seguromovil.name:
                    return navLink.seguromovil
                case landing.seguropatinete.name:
                    return navLink.seguropatinete
                case landing.seguromultidispositivo.name:
                    return navLink.seguromultidispositivo
                case landing.seguroportatil.name:
                    return navLink.seguroportatil
                case landing.extensiondegarantia.name:
                    return navLink.extensiondegarantia
                case landing.insurama.name:
                    return navLink.insurama
                default:
                    return hRef
            }
        },
        faqsButtonClicked(){
            bus.$emit(events.faqsButtonClicked);
        }
    }
}
</script>

<style lang="scss" scoped>
.footer-content{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    
}

.footer-content.pt, .footer-content.mx {
    justify-content: flex-start;
}

.footer-content.pt .footer-description:nth-child(2), .footer-content.mx .footer-description:nth-child(2){
    margin-top: 0;
}

.footer-content .footer-description:nth-child(2){
    margin-top: 30px;
}
.footer-content .footer-description{
    width: 25%;
}
@media (max-width: 992px){
    
    .footer-content .footer-description{
        width: 50%;
    }
}
@media (max-width: 580px){
    .footer-content .footer-description:nth-child(2){
        margin-top: 0;
    }
    .footer-content .footer-description{
        width: 100%;
    }
}

#top-buttons {
    margin-bottom: 40px;
    text-align: center;
}

footer .header {
    color: $orange-color;
    text-transform: uppercase;
    font-weight: 500;
}

footer a {
    text-decoration: none;
    color: $blue-color-light;
}

footer #footer-links {
    margin-top: 30px
}
</style>
