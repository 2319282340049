<template>
    <header class="header-menu" :class="[`${currentLanding}`]">
        <HeaderOptions :currentLanding= this.currentLanding />
        <HeaderInsurances :currentLanding= this.currentLanding />
    </header>
</template>

<script>
import HeaderOptions from './HeaderOptions.vue'
import HeaderInsurances from './HeaderInsurances.vue'
import { getters } from '@/state/store.js'

export default {
    name: "HeaderComponent",
    components: {
        HeaderInsurances,
        HeaderOptions
    },
    computed: {
        currentLanding: getters.currentLanding
    }
}
</script>

<style lang="scss">
.header-menu {
    position: sticky;
    top: 0;
    padding-bottom: 0;
    background-color: rgba(255,255,255,.98);
    z-index: 103;
}

.wrapper-menu {
    background-color: $white-color;
}
.container-menu {
    max-width: 1430px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    padding: 0 16px;
}
.wrapper-menu--contact .container-menu {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 0 20px;
    grid-template-areas: "logo nav";
}
.wrapper-menu--contact .container-menu__logo { grid-area: logo; }
.wrapper-menu--contact .container-menu__nav { grid-area: nav; }
.wrapper-menu--contact .container-menu__logo,
.wrapper-menu--contact .container-menu__nav {
    height: 70px;
    display: flex;
    align-items: center;
}

.container-menu [data-sum-telefono="ringpool"] span {
    min-width: calc(10ch + 2.1px);
    display: inline-block;
}

.container-menu__logo .nav-logo{
    width: 80px;
    height: auto;
}
//tamaño logo por dispositivo//
.seguromultidispositivo .container-menu__logo .nav-logo{
    width: 140px;
}

.container-menu__logo.pt .nav-logo
 {
    max-width: 150px;
    width: 100%;
}


.container-menu__logo.mx .nav-logo {
    max-width: 230px;
    width: 100%;
}

.menu-logo {
    width: 100%;
    height: auto;
}

.menu-contact-mobile-version {
    display: grid;
    grid-template-columns: repeat(2,auto);
    gap: 0 30px;
    padding-right: 30px;
    align-items: center;
}

.wrapper-menu--contact .container-menu__nav .btn-orange {
    background: $orange-color;
    text-align: center;
    color: $white-color;
    border: 0;
    border-radius: 5px;
    letter-spacing: .9px;
    padding: 7px 20px;
}
.wrapper-menu--contact .container-menu__nav .btn-orange:hover {
    color: $white-color;
}
.container-menu ul {
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
}

.wrapper-menu--contact .container-menu__nav .menu-contact {
    display: none;
    grid-template-columns: repeat(4, auto);
    gap: 0 calc(28px + 2vw);
    margin-right: 50px;
}
.wrapper-menu--insurances .container-menu__nav li {
    height: 59px;
    border-bottom: 1px solid $blue-color;
    display: flex;
    align-items: center;
}
.wrapper-menu--insurances .container-menu__nav .btn-show-apps-dropdwon {
    height: auto;
    min-height: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.wrapper-menu--insurances .container-menu__nav .btn-show-apps-dropdwon > a {
    height: 50px;
    padding-left: 0;
}
.wrapper-menu--insurances .container-menu__nav .btn-show-apps-dropdwon > a i {
    text-align: left;
}
.wrapper-menu--insurances .container-menu__nav .btn-show-apps-dropdwon > a:focus {
    box-shadow: none;
}
.wrapper-menu--insurances .container-menu__nav .btn-show-apps-dropdwon .app-stores-btns {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
}

.google-play-white {
    //url(~@/assets/header/icon-google-play.svg)
    background: url('https://www.sella.it/banca-online/static/landing/app-invest/img/google-play-badge@2x.png') 0 center no-repeat;
    background-size: contain;
    content: '';
    display: inline-block;
    height: 40px;
    width: 135px;
    margin: 0 10px 10px 0;
}

.app-store-white {
    //url(~@/assets/header/icon-app-store.svg)
    background: url('https://www.sella.it/banca-online/static/landing/app-invest/img/app-store-badge@2x.png') 0 center no-repeat;
    background-size: contain;
    content: '';
    display: inline-block;
    height: 40px;
    width: 135px;
    margin: 0 10px 10px 0;
}
.wrapper-menu--insurances .container-menu__nav .menu-contact {
    border-top: 1px solid $blue-color;
}
.wrapper-menu--insurances .container-menu__nav .menu-contact,
.wrapper-menu--insurances .container-menu__nav .menu-insurances {
    display: flex;
    flex-direction: column;
}

.wrapper-menu--contact .container-menu__nav a,
.wrapper-menu--insurances .container-menu__nav a {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    color: $blue-color;
    transition: color 0.3s 0s ease-in-out;
}

.wrapper-menu--contact .container-menu__nav a:hover,
.wrapper-menu--insurances .container-menu__nav a:hover {
    color: $orange-color;
    text-decoration: none;
    transition: color 0.3s 0s ease-in-out;
}
.wrapper-menu--contact .container-menu__nav .whatsapp,
.wrapper-menu--insurances .container-menu__nav .whatsapp {
    color: $whatsapp-color;
}
.container-menu .menu-insurances img {
    height: 36px;
}

.container-menu .menu-insurances p {
    font-size: 15px;
    font-weight: 600;
}

.menu-contact i {
    width: 22px;
}
.menu-insurances__img {
    width: 60px;
}

body {
    height: initial !important;
}

.wrapper-menu--contact {
    z-index: 1003;
    position: sticky;
    top: 0;
}

.ham {
    --button-width: 32px;
    --button-height: var(--button-width);
    --button-transistion-duration-x: 0.2s;
    --button-transistion-duration-angle: 0.15s;
    --pad-factor: 0;
    --thickness-factor: 0.1;
    --button-pad: calc(var(--button-width) * var(--pad-factor));
    --bar-thickness: calc(var(--button-width) * var(--thickness-factor));
    --ham-line-color: tomato;
    display: block;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: var(--button-width);
    height: var(--button-height);
    font-size: 0;
    text-indent: -9999px;
    background-color: $white-color;
    appearance: none;
    box-shadow: none;
    border-radius: 0;
    border: none;
    cursor: pointer;
    margin-left: 10px;
}
.ham::before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: var(--button-width) 0 0 calc(var(--button-width) / 2.5);
    border-color: transparent transparent transparent $white-color;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    transition-duration: var(--button-transistion-duration-angle);
    transition-property: border-width;
}
.ham:focus {
    outline: none;
}
.ham span {
    display: block;
    position: absolute;
    top: calc(var(--button-width) * 0.45833333);
    left: var(--button-pad);
    right: var(--button-pad);
    height: var(--bar-thickness);
    background: var(--ham-line-color);
}
.ham span::before,
.ham span::after {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: var(--bar-thickness);
    background-color: var(--ham-line-color);
    content: "";
    transition-duration: var(--button-transistion-duration-x), var(--button-transistion-duration-x);
    transition-delay: var(--button-transistion-duration-x), 0s;
}
.ham span::before {
    top: calc(var(--button-width) * -0.3);
    transition-property: top, transform;
}
.ham span::after {
    bottom: calc(var(--button-width) * -0.3);
    transition-property: bottom, transform;
}
.nav-open .ham::before {
    border-width: 46px 0 0 0;
    transition-property: border-width;
}
.nav-open .ham span {
    background: none;
}
.nav-open .ham span::before {
    top: 0;
    transform: rotate(45deg);
}
.nav-open .ham span::after {
    bottom: 0;
    transform: rotate(-45deg);
}
.nav-open .ham span::before,
.nav-open .ham span::after {
    transition-delay: 0s, var(--button-transistion-duration-x);
}

@media(min-width: 1140px) {
    .ham {
        display: none !important;
    }
    .menu-contact-mobile-version {
        display: none;
    }
    .wrapper-menu--insurances .container-menu__nav .menu-contact {
        display: none;
    }
    .wrapper-menu--contact .container-menu__nav .menu-contact {
        display: grid;
    }
    .wrapper-menu--insurances {
        z-index: 1003;
        position: sticky;
        top: 50px;
        background-color: $gray-color-light;
    }
    .wrapper-menu--insurances .container-menu__nav .menu-insurances {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        gap: 10px 5%;
        min-height: 90px;
        align-items: center;


    }
    
    .mx .wrapper-menu--insurances .container-menu__nav .menu-insurances {
        justify-content: center;
        gap: 10px 30%;
    }

    .wrapper-menu--insurances .container-menu__nav .menu-insurances a {
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
    }
    .wrapper-menu--insurances .container-menu__nav .menu-insurances img {
        margin-right: 10px;
        height: 45px;
    }

    .wrapper-menu--insurances .container-menu__nav .menu-insurances .menu-insurances__img.pt img,
    .wrapper-menu--insurances .container-menu__nav .menu-insurances .menu-insurances__img.mx img {
        height: 75px;
    }

    .wrapper-menu--insurances .container-menu__nav .menu-insurances .block-line {
        display: block;
    }
    .wrapper-menu--insurances .container-menu__nav li {
        border-bottom: 0;
    }

    .menu-insurances__img {
        width: auto;
    }
    .menu-contact i {
        width: auto;
    }
}
@media(max-width: 1139.98px) {
    .wrapper-menu--insurances {
        --ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);
        --duration: 1.6s;
        --duration-fade-in: .3s;
        --duration-fade-out: 1s;
        position: fixed;
        left: 0;
        right: 0;
        z-index: 102;
        max-height: 100vh;
        overflow: hidden auto;
        overscroll-behavior: contain;
        background: rgba(255,255,255,0);

        visibility: hidden;
        transform: translateY(calc(-100vw - 55px));
        will-change: transform;
        //transition: 
        //    transform var(--duration) var(--ease-out-expo),
        //    visibility 0s linear var(--duration);
    }
    .wrapper-menu--insurances .container-menu {
        overflow: hidden auto;
        background: rgba(255,255,255,0);
        transition: background var(--duration-fade-out);
    }
    @media (prefers-reduced-motion: reduce) {
        .wrapper-menu--insurances {
            --duration: 1ms;
            --duration-fade-in: 1ms;
            --duration-fade-out: 1ms;
        }
    }
    .nav-open .wrapper-menu--insurances {
        margin-top: 55px;
        visibility: visible;
        transform: translateY(0);
        transition: transform var(--duration) var(--ease-out-expo);
    }
    .nav-open .wrapper-menu--insurances .container-menu {
        height: calc(100vh - 50px);
        transition: background var(--duration-fade-in);
        background: $white-color;
    }
}

@media(max-width: 799.98px) {
    .menu-contact-mobile-version {
        gap: 0 calc(15px + .5vw);
        margin-right: calc(15px + .5vw);
        padding-right: calc(15px + .5vw);
    }
    .menu-contact-mobile-version a span {
        display: none !important;
    }
    .menu-contact-mobile-version a i {
        font-size: calc(15px + .5vw);
    }
}
@media(max-width: 699.98px) {
    .wrapper-menu--contact .container-menu {
        padding: 0 6px;
        gap: 0 12px;
    }
    .wrapper-menu--contact .container-menu__nav .btn-orange {
        font-size: calc(8px + 1vw);
        padding: 5px 8px;
    }
    .menu-logo {
        max-height: calc(26px + 2vw);
    }
}
@media(max-width: 499.98px) {
    .menu-contact-mobile-version {
        gap: 0 5px;
        margin-right: 5px;
        padding-right: 5px;
    }
    .menu-contact-mobile-version a i {
        font-size: calc(10px + 1vw);
    }
    .menu-logo {
        max-height: calc(19px + 2vw);
    }
    .ham {
        --button-width: 26px;
        --thickness-factor: 0.12;
    }
}
@media(max-width: 399.98px) {
    //.wrapper-menu--contact .container-menu__nav .btn-orange {
    //    font-size: 9px;
    //    letter-spacing: 0;
    //    padding: 5px 8px;
    //}
    
    .ham {
        --button-width: 20px;
    }
}
@media(max-width: 359.98px) {
    .ham {
        margin-left: 7px;
    }
}

@media only screen and (max-width: 768px) {

     .wrapper-menu--contact .container-menu{
        width: inherit;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap:nowrap;
        overflow-x: hidden;
        margin:0;
    }

    .nav-logo{
        justify-content: flex-start;
    }

    .wrapper-menu--contact .container-menu__logo{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0;
        flex-wrap: nowrap;
        flex: 1 1 0;
        min-width: 0;
    }

    .wrapper-menu--contact .container-menu .container-menu__nav{
        display: flex;
        justify-content: flex-end;
        padding: 0;
        flex-wrap: nowrap;
        flex: 1 1 0;
        min-width: 0;

    }



}

</style>
