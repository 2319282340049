<template>
  <transition name="">
     <b-modal ref="thanksRef" centered hide-header hide-footer>
         <div class="modal-center">
             <a class="btn-close-modal pr-4 pointer" @click="hideModal" style="font-size:30px;">
                <mdi-icon icon="mdiClose" />
             </a>
             <br>
            <div class="text-center pt-4">
                <h3  style="color:#00aa00"> <strong>{{ $t('thanksModal.title') }}</strong></h3>
                <div class="row">
                    <div class="col-12 col-md-10 offset-md-1">
                        {{ $t('thanksModal.message') }}
                        <br><br> 
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
  </transition>
</template>

<script>

export default { 
    methods: {
        showModal() {
            this.$refs.thanksRef.show()
        },
        hideModal() {
            this.pushGTM('funnel', 'cancelar', 'recurrente')
            this.$refs.thanksRef.hide()
        }
    }
}
</script>

<style lang="scss" scoped>

</style>